var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "child-flex": "" } },
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.brands,
          "item-key": "name",
          "show-group-by": "",
          "disable-pagination": "",
          "hide-default-footer": "",
          "loading-text": _vm.$t("brand.table.loadingList"),
          "no-data-text": _vm.$t("brand.table.noFound"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }