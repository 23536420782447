var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-dialog", {
    attrs: { scrollable: "", "max-width": "800" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "" } },
                    [
                      !_vm.storedBrandQuery.id
                        ? _c("v-toolbar-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configureStoredBrandQueryPopup.titleCreate"
                                )
                              )
                            ),
                          ])
                        : _c("v-toolbar-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "configureStoredBrandQueryPopup.titleEdit"
                                )
                              )
                            ),
                          ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pt-6 pb-0" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { light: "" },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: { "background-color": "transparent" },
                              model: {
                                value: _vm.selectedTab,
                                callback: function ($$v) {
                                  _vm.selectedTab = $$v
                                },
                                expression: "selectedTab",
                              },
                            },
                            [
                              _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "configureStoredBrandQueryPopup.tabCommon"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "configureStoredBrandQueryPopup.tabAgent"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              staticClass: "my-5",
                              model: {
                                value: _vm.selectedTab,
                                callback: function ($$v) {
                                  _vm.selectedTab = $$v
                                },
                                expression: "selectedTab",
                              },
                            },
                            [
                              _c(
                                "v-tab-item",
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "configureStoredBrandQueryPopup.name"
                                                  ),
                                                  required: "",
                                                  rules: [
                                                    _vm.$validation.required,
                                                  ],
                                                },
                                                model: {
                                                  value: _vm.name,
                                                  callback: function ($$v) {
                                                    _vm.name = $$v
                                                  },
                                                  expression: "name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  "auto-grow": true,
                                                  rows: 3,
                                                  label: _vm.$t(
                                                    "configureStoredBrandQueryPopup.description"
                                                  ),
                                                },
                                                model: {
                                                  value: _vm.description,
                                                  callback: function ($$v) {
                                                    _vm.description = $$v
                                                  },
                                                  expression: "description",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-btn-toggle",
                                                {
                                                  staticClass: "ml-n1",
                                                  attrs: {
                                                    mandatory: "",
                                                    tile: "",
                                                    group: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.agentExecutionFrequency,
                                                    callback: function ($$v) {
                                                      _vm.agentExecutionFrequency =
                                                        $$v
                                                    },
                                                    expression:
                                                      "agentExecutionFrequency",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    { attrs: { value: null } },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "configureStoredBrandQueryPopup.agentExecutionFrequencyNoneButton"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { value: "Daily" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "configureStoredBrandQueryPopup.agentExecutionFrequencyDailyButton"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        value: "Weekly",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "configureStoredBrandQueryPopup.agentExecutionFrequencyWeeklyButton"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        value: "Monthly",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "configureStoredBrandQueryPopup.agentExecutionFrequencyMonthlyButton"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          !_vm.agentExecutionFrequency
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pt-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "configureStoredBrandQueryPopup.agentExecutionFrequencyNoneDescription"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.agentExecutionFrequency ===
                                          "Daily"
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pt-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "configureStoredBrandQueryPopup.agentExecutionFrequencyDailyDescription"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.agentExecutionFrequency ===
                                          "Weekly"
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pt-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "configureStoredBrandQueryPopup.agentExecutionFrequencyWeeklyDescription"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.agentExecutionFrequency ===
                                          "Monthly"
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pt-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "configureStoredBrandQueryPopup.agentExecutionFrequencyMonthlyDescription"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.sizes,
                                                  "return-object": "",
                                                  label: _vm.$t(
                                                    "configureStoredBrandQueryPopup.size"
                                                  ),
                                                  "persistent-hint": "",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item",
                                                    fn: function (ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "configureStoredBrandQueryPopup.numberOfResults",
                                                                { number: item }
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "selection",
                                                    fn: function (ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "configureStoredBrandQueryPopup.numberOfResults",
                                                                { number: item }
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                                model: {
                                                  value: _vm.selectedSize,
                                                  callback: function ($$v) {
                                                    _vm.selectedSize = $$v
                                                  },
                                                  expression: "selectedSize",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "configureStoredBrandQueryPopup.ownerMail"
                                                  ),
                                                  readonly: "",
                                                  required: "",
                                                  rules: [
                                                    _vm.$validation.required,
                                                  ],
                                                },
                                                model: {
                                                  value: _vm.ownerMailAddress,
                                                  callback: function ($$v) {
                                                    _vm.ownerMailAddress = $$v
                                                  },
                                                  expression:
                                                    "ownerMailAddress",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-combobox", {
                                                attrs: {
                                                  rules:
                                                    _vm.agentExecutionFrequency
                                                      ? [_vm.mailAddressesValid]
                                                      : [],
                                                  items:
                                                    _vm.additionalMailAddresses,
                                                  disabled:
                                                    !_vm.agentExecutionFrequency,
                                                  delimiters: [" ", ";", ","],
                                                  chips: "",
                                                  multiple: "",
                                                  label: _vm.$t(
                                                    "configureStoredBrandQueryPopup.additionalRecipients"
                                                  ),
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "selection",
                                                    fn: function (ref) {
                                                      var attrs = ref.attrs
                                                      var item = ref.item
                                                      var select = ref.select
                                                      var selected =
                                                        ref.selected
                                                      return [
                                                        _c(
                                                          "v-chip",
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                "input-value":
                                                                  selected,
                                                                close: "",
                                                              },
                                                              on: {
                                                                click: select,
                                                                "click:close":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeMailAddress(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "v-chip",
                                                            attrs,
                                                            false
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(item) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                                model: {
                                                  value:
                                                    _vm.selectedAdditionalMailAddresses,
                                                  callback: function ($$v) {
                                                    _vm.selectedAdditionalMailAddresses =
                                                      $$v
                                                  },
                                                  expression:
                                                    "selectedAdditionalMailAddresses",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.locales,
                                                  label: _vm.$t(
                                                    "configureStoredBrandQueryPopup.notificationLanguage"
                                                  ),
                                                  "persistent-hint": "",
                                                  "return-object": "",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item",
                                                    fn: function (ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(item.text)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "selection",
                                                    fn: function (ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(item.text)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                                model: {
                                                  value:
                                                    _vm.selectedNotificationLanguage,
                                                  callback: function ($$v) {
                                                    _vm.selectedNotificationLanguage =
                                                      $$v
                                                  },
                                                  expression:
                                                    "selectedNotificationLanguage",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "", disabled: _vm.selectedTab <= 0 },
                          on: {
                            click: function ($event) {
                              _vm.selectedTab = _vm.selectedTab - 1
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(" fa-light fa-chevron-left "),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "configureStoredBrandQueryPopup.buttonBack"
                                )
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "", disabled: _vm.selectedTab >= 1 },
                          on: {
                            click: function ($event) {
                              _vm.selectedTab = _vm.selectedTab + 1
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "configureStoredBrandQueryPopup.buttonNext"
                                )
                              ) +
                              " "
                          ),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v(" fa-light fa-chevron-right "),
                          ]),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.valid,
                            plain: "",
                            loading: _vm.busyStoredBrandQuery.isBusy,
                          },
                          on: { click: _vm.save },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("configureStoredBrandQueryPopup.save")
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }